import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { ACTIVITY_TYPES } from 'state-domains/constants';

import { buildCopyRowReferencesUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken, convertToCamelWithSpecialParameters } from '../../../utils';
import { Drillhole, RowReference, RowReferenceResponse } from '../types';

export const copyCells = (
    rowReferences: any[],
    type: ACTIVITY_TYPES,
    queryParameters: any = {},
): Observable<RowReferenceResponse> => {
    const url = buildCopyRowReferencesUrl(type, queryParameters);
    return sendRequestWithXSRFToken(url, rowReferences).pipe(
        switchMap(({ response }: AjaxResponse<RowReferenceResponse>) => {
            const { RowReference, Collar } = response;
            const convertedReference = convertToCamelWithSpecialParameters<RowReference[]>(
                RowReference,
                { id: 'uid' },
            );
            const convertedCollar = convertToCamelWithSpecialParameters<Drillhole>(Collar, {
                id: 'uid',
                _id: 'id',
            });

            const convertedNextRows = convertToCamelWithSpecialParameters<Record<string, RowReference>>(response.NextRows ?? {}, { id: 'uid' });
            return observableOf({
                RowReference: convertedReference,
                Collar: convertedCollar,
                NextRows: convertedNextRows,
            } as RowReferenceResponse);
        }),
    );
};
